import { Injectable } from '@angular/core';
import { HttpEvent, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';

import { Adal5Service } from 'adal-angular5';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private router: Router, private adalService: Adal5Service) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updateRequest, errors = [400, 401, 403];
    updateRequest = req.clone({
      headers: req.headers.append('Access-Control-Allow-Origin', '*')
        .append('Content-Type', 'application/json')
        .append("Access-Control-Allow-Methods", "GET,POST,PUT,DELETE,OPTIONS")
        .append("Access-Control-Allow-Methods", "GET,POST,PUT,DELETE")
    })
    if (this.adalService.userInfo.authenticated) {
      updateRequest = req.clone({
        headers: req.headers.append('Authorization', "Bearer " + this.adalService.userInfo.token)
      })
      return next.handle(updateRequest).catch(err => {
        if (err instanceof HttpResponse) {
          if(errors.indexOf(err.status) >= 0) {
            this.adalService.login();
          }
        }
        return Observable.throw(err);
      });
    } else {
      return next.handle(req).catch(err => {
        if (err instanceof HttpResponse) {
          if(errors.indexOf(err.status) >= 0) {
            this.adalService.login();
          }
        }
        return Observable.throw(err);
      });
    }
    /* .catch((err: HttpErrorResponse) => {
        return Observable.throw(err);
      }
    ); */
  }
}