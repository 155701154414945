import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferselectionTabRoutingModule } from './offerselection-tab-routing.module';
import { OfferSelectionComponent } from './offer-selection/offer-selection.component';
// import { NewofferselComponent } from '../newoffersel/newoffersel.component';
import { OfferselectionTabComponent } from './offerselection-tab.component';
// import { AgGridModule } from "ag-grid-angular/main";
import { GridComponent } from './grid/grid.component';
import { OrderByPipe } from '../../order-by.pipe';
import { OfferselectionNewGridComponent } from './offerselection-new-grid/offerselection-new-grid.component';
import { CanActivateGuard } from './offerselection-active-gaurd';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {NgBusyModule} from 'ng-busy';


@NgModule({
  imports: [
    CommonModule,
    OfferselectionTabRoutingModule,
    TranslateModule,
    NgBusyModule
  ],
  declarations: [OfferselectionTabComponent, GridComponent, OfferselectionNewGridComponent],
  providers: [
    CanActivateGuard
  ]
})
export class OfferselectionTabModule { }
