import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import { apiurl } from '../../assets/data/globals';

@Injectable()
export class TranslationService implements TranslateLoader {

    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any> {

        if(localStorage.getItem("country")=="brazil") // nxk22478 brazil
        {
            lang="pt";
        }
        localStorage.setItem("language",lang);
        return this.http.get(apiurl + "api/getLabels?language=" + lang)
            .map((response: JSON) => {
                return response;
            });
    }
}