import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
//import { EventProvider } from './services/event-provider';
import { OnlineStatusComponent } from '../app/online-status/online-status.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []
})

export class AppComponent implements OnInit {
  title = 'app';
  // mxb11725 
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  bgSyncStatusMessage: string;
  bgSyncStatus: string;
   //@Stated: Vinay: Added For Background Sync
  @ViewChild(OnlineStatusComponent, {static: false}) oSComp: OnlineStatusComponent;
  @Output() complete:EventEmitter<any> = new EventEmitter<any>();

   //@Ended: Vinay: Added For Background Sync
  

  
  // end here
  constructor(private _translate:TranslateService) {
    //localStorage.setItem("repcode", "CDE 13");    
    localStorage.setItem("applyFilterAction", "false");
    this._translate.addLangs(['en', 'tr', 'es','pt']); // brazil nxk22478 10/3
    this._translate.setDefaultLang('en');
    this._translate.use('en');
    const browserLang = _translate.getBrowserLang();
    _translate.use(browserLang.match(/en|tr|es|pt/) ? browserLang : 'en'); // brazil nxk22478 10/25
    // console.log(this.eventProvider.currentLang);
    // this.eventProvider.currentLang.subscribe(lang => {
    //   console.log("event loader ****************",lang);
    //   this._translate.use(lang);
    // });
  }
  
// mxb11725 
  ngOnInit(): void {
    /**
    * Get the online/offline status from browser window
    */
   //alert(JSON.parse(localStorage.getItem("pickItem")));
  // if(JSON.parse(localStorage.getItem("pickItem"))!=null || JSON.parse(localStorage.getItem("bgSync"))!=null)
// {
 //  alert("YES");
// }
   this.bgSyncStatusMessage ='Internet connection seems to be lost.'; // , you can use application in offline mode.

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
  
  
    this.subscriptions.push(this.onlineEvent.subscribe(e => {  
      //@Stated: Vinay: Added For Background Sync
      sessionStorage.setItem("HandleOSP","true");      
      this.oSComp.bSSH();
      //@Ended: Vinay: Added For Background Sync
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      console.log('Online...');     
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      //@Stated: Vinay: Added For Background Sync
      sessionStorage.setItem("HandleOSP","false");
     

      this.connectionStatusMessage ='Internet connection seems to be lost.'; // , you can use application in offline mode.
      this.connectionStatus = 'offline';
      console.log('Offline...');
    }));
  }

  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // end here

  
}
