import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  } from '@angular/router';

import { OfferselectionTabComponent } from './offerselection-tab.component';

import { HeaderService } from '../components/header/header.service';
//import { forEach } from '@angular/router/src/utils/collection';
//import { forEach } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class CanActivateGuard implements CanActivate {

  constructor(private headerService: HeaderService,
              private translate: TranslateService) { }

  canActivate() {
    //return true;
    var applyfilter = localStorage.getItem("applyFilterAction");
    //applyfilter="true";
   // alert(applyfilter+"applyfilter");
    var routerFlag = false;
    var _this = this;
    if (applyfilter == "true") {
      routerFlag = true;
      /*this.headerService.getSelectedFilterItems().forEach(function(obj){
        var key = obj[0], value = obj[1];
        
        if (key === "pharmacy") {
          //alert(value);
          if (value.length === 0) {
           alert("Please select only one pharmacy1");
           routerFlag = false;
          } else if (value.length > 1) {
            alert("Please select only one pharmacy2");
            routerFlag = false;
          } else{
            routerFlag = true;
          }
        }
      });*/
      return routerFlag;
    } else {
         alert("Please select only one pharmacy3");
      return routerFlag;
    }
  }
}