import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfferSelectionComponent } from './offer-selection/offer-selection.component';
// import { NewofferselComponent } from '../newoffersel/newoffersel.component';
import { OfferselectionTabComponent } from './offerselection-tab.component';
import { GridComponent } from './grid/grid.component';
import { OfferselectionNewGridComponent } from './offerselection-new-grid/offerselection-new-grid.component';
import { CanActivateGuard } from './offerselection-active-gaurd';
import { AuthenticationGuard } from './../../authentication-guard';

const routes: Routes = [{
  path: '',
  component: OfferselectionTabComponent,
  // canActivate: [AuthenticationGuard],
  // canActivateChild: [CanActivateGuard, AuthenticationGuard],
  children: [{
    path: '',
    component: OfferselectionNewGridComponent,
    // loadChildren: '././offer-selection/offer-selection.module#OfferSelectionModule',
    canActivate: [CanActivateGuard, AuthenticationGuard]
  }, {
    path: 'newofferselection',
    component: OfferselectionNewGridComponent,
    canActivate: [CanActivateGuard, AuthenticationGuard]
  }, {
    path: 'offerselection',
    loadChildren: () => import('././offer-selection/offer-selection.module').then(m => m.OfferSelectionModule),
    // canActivate: [CanActivateGuard]
  }]
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfferselectionTabRoutingModule { }
