import { Component, Input, OnChanges, ViewChild, ElementRef, AfterContentChecked, SimpleChanges, AfterViewChecked,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { HeaderService } from '../../components/header/header.service';
import { OfferService } from '../offerselection-new-grid/offerselection-new-grid-service';
import { GskNumberPipe } from '../../../gsk-number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import {debounceTime} from 'rxjs/operators';
import { ExitStatus } from 'typescript';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { fromEvent,Observable,Subscription } from 'rxjs';


@Component({
  selector: 'gsk-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GridComponent implements OnChanges, AfterContentChecked {
  @ViewChild('offerSelect', {static: false}) offerSelect: ElementRef;
  @ViewChild('close', {static: false}) close: ElementRef;

  @Input() tableHeads: Array<any> = new Array<any>();
  @Input() tableDatas: Array<any> = new Array<any>();
  @Input() tableColName: Array<String> = new Array<String>();
  @Input() tableAggrData: any = {};
  @Input() isLegendShow: any;
  @Input() speedGaugeObj:any;
  //d3: any;
  private tableColNameGenerated: Array<String> = new Array<String>();
  private isTableColNameSet: Boolean = false;
  // private tableAggrData: Object = {};

  private key: string = 'product_name'; //set default sort key
  private reverse: boolean = true; // set default sort order
  // selectedOffer = null;
  private offer: any = {};
  //private offerTmp: any = {};
  //private element: HTMLElement;
  private offertmp1: string;
  private offersList: Array<any> = [];
  private legends: Array<any> = [];
  gskNumberPipe: any
  
  drawInvoke:boolean=false;
  dynIdPG: any="";
  dynIdwrapper: any="";
  speedGaugeLable: any="";
  speedGaugeLableBool:any=true;
  dynIdleftcolumn:any="";
  gapVolume:boolean=false;
  colorGapVolume:any = "";
  busy: Subscription;
  subscriptionOne: Subscription[] = [];
  checkRowCount: any="";
  //Vinay Start: Background Sync
  
  bgArrBlocking:any = true;
  onlineEvent: Observable<Event>;
  //Vinay End: Background Sync

  //Vinay Start: Offer Unlock
reasionUnlock:any;
chkBoxRow:any;

  //Vinay End: Offer Unlock 


  private uniq(a, param) {
    return a.filter(function (item, pos, array) {
      return array.map(function (mapItem) { return mapItem[param]; }).indexOf(item[param]) === pos;
    })
  }

  constructor(private headerService: HeaderService, private offerService: OfferService, private chr: ChangeDetectorRef, private translate: TranslateService,private http: HttpClient, private element: ElementRef) {
    //alert('inside grid component');
    var __this = this;
    this.gskNumberPipe = new GskNumberPipe()
     this.headerService.lngChange.pipe(debounceTime(500)).subscribe(() => {
      this.updateLegends();
     
    });  
  }

  ngOnInit() {
    //id="unlock2"
   this.getGridLegends();  
   
   sessionStorage.setItem("bgb","true");
   this.onlineEvent = fromEvent(window, 'online');

   this.subscriptionOne.push(this.onlineEvent.subscribe(e => {    
  
  }));

  }

  ngAfterContentChecked(){
    //console.log("table data",document.querySelectorAll("tbody"));
  }

  RespondMouseOver(){return false;}
  //ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) { }

  getGridLegends() {
    var __this = this;
   this.headerService.getOfferstatusForOfferSelection().subscribe(res => {
      __this.offersList = res;

      console.log(' get offersstatusforselection list'+JSON.stringify(__this.offersList));
      /** 
       * [{"status_id":1,"mapping_value":"Offer accepted","color":"#28a745","legend":"Accepted"},
       * {"status_id":2,"mapping_value":"Offer rejected - Proposed Volumes too optimistic","color":"#dc3545","legend":"Rejected"},
       * {"status_id":3,"mapping_value":"Offer rejected - Financially not viable/Discounts too low",
       * "color":"#dc3545","legend":"Rejected"},{"status_id":6,"mapping_value":"Offer extended, 
       * response awaited","color":"#ffc107","legend":"Awaited"},
       * {"status_id":7,"mapping_value":"Offer not yet extended","color":"#ffffff","
       * legend":"Not yet extended"},{"status_id":8,"mapping_value":"inactive","color":"#D3D3D3",
       * "legend":"Inactive"}]
       * 
      */
      __this.updateLegends();
    });
    
  }

  
  getIdValuePG() {
    // snip snip -> fetch the url from somewhere    
    if(this.speedGaugeObj){ this.dynIdwrapper = "power-gauge";  }      
    return this.dynIdwrapper;
  }

   getIdValueWrapper() {
    if(this.speedGaugeObj){ 
      if(localStorage.getItem("country") == 'brazil'){
      this.dynIdPG = "wrapper" 
      }    
    }     
    return this.dynIdPG;
  }

  getIdValueleftcolumn() {
   if(this.speedGaugeObj ){ 
      if(localStorage.getItem("country") == 'brazil'){
      this.dynIdleftcolumn = "leftcolumn" 
      }
    }     
    return this.dynIdleftcolumn;
  }


 
gapPushColor(i){  
  //console.log("counter index:::: => "+i);   
  this.colorGapVolume = this.offerService.gapVolumeColorCollection()[i];   

  return this.colorGapVolume;
}

  updateLegends() {
    var __this = this;
    __this.offersList.forEach(function (obj) {
      obj["transmapping_value"] = "labels."+obj['mapping_value'];
      __this.legends.push({
        "id": obj['status_id'],
        "status": obj['legend'],
        "transstatus": "labels."+obj['legend'],
        "convstatus": __this.translate.instant('labels')[obj['legend']],
        "color": obj['color']
      });
    });
    __this.legends = __this.uniq(__this.legends, 'status');
  }

  /**
  * This method will fetch all the property name and convert it into a list of String.
  * @param {Array<String>} head Pass in the list of String, which contains table header values
  * @param {Array<String>} col Pass in the list of String, which contains column property 
  * name, which was received from Input or generated using this.getKeys()
  */
  private isHeadAndColLengthSame(head: Array<String>, col: Array<String>): Boolean {
  //  alert("isHeadAndColLengthSame");
    return (head.length === col.length);
  }

  /**
  * This method will fetch all the property name and convert it into a list of String.
  * @param {any} value Pass Instance of Object eg. new User()
  */
  private getKeys(value: any): Array<String> {
    //alert("getKeys");
    return Object.keys(value);
  }

  private sort(key) { // To sort column data
    this.key = key;
    this.reverse = !this.reverse;
    // this.sortByKey(this.tableDatas, key);
  }

  private sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  private toggleIcon(column) { // To keep current sorting column icon visible
   // alert("toggleIcon");
    this.tableHeads.forEach(function (obj) {
      if (!obj.checkbox) {
        if (column.key !== obj.key) {
          obj.icon = false;
        } else {
          obj.icon = true;
        }
      }
    });
  }

  private toggleCollapse(groupIndex) {
   // alert("toggleCollapse"+groupIndex);
    this.tableDatas.forEach(function (obj) {
      if (obj.group === groupIndex) {
        obj.collapsed = !obj.collapsed;
      }
    });
  }

private offermsg: any;
private dropDownOUnlock:any;
public unlockReasion: any=["Offer accidently selected", "Pharmacy’s request"];

  private offerSelection(event, parameter, planindex, dataindex, checked) { 
   //this.tableDatas[dataindex][parameter] = event.target.checked;

   if(parameter.indexOf("unlock") > -1){
      this.dropDownOUnlock = 'true';
   }
   else{
    this.dropDownOUnlock = 'false';
   // alert("Inside else false");
   }

    this.offer['selectedOffer'] = null;    
    this.offer['key'] = parameter;
    this.offer['index'] = dataindex;
    this.offer['plan'] = planindex;
    this.offer['checked'] = event.target.checked;
    
    this.offertmp1=parameter+dataindex;
    //alert("will it work:  "+this.offertmp1);

    
    console.log("event.target.checked::",event.target.checked);
    console.log("event::",event);
    console.log("parameter::",parameter);
    console.log("planindex::",planindex);
    console.log("event::",event);
  

    if(parameter.indexOf("unlock") > -1)
    {
      this.offermsg = "Please select reason for unlock offer:";     
    }
    else
    {
      this.offermsg = "please select offer:";
    }

    if(navigator.onLine == false){
      if(parameter.indexOf("unlock") > -1){
      alert("'Unlocking a Request' necessitates online connection.");   
      this.close.nativeElement.click();      
      return false;
      }
    }

    if(this.offer['checked'])
    {
      this.chr.detectChanges();
      this.checkRowCount = dataindex;
    }   
  }

  private clearOffer(event) {
   // alert(this.offertmp1);
    //document.getElementById(this.offertmp1).click();
    //alert(event.target.value +"--clear");
    //this.tableHeads.keys
    //this.offer['checked'] = event.target.Uncheck;
    //this.tableDatas[this.offer['index']][this.offer['key']] = false; // Use: Uncheck checkbox when user clicks cancel option in popup
    if(event.target.value=='on')
    {
      this.isLegendShow='false';
      this.speedGaugeObj='false';
      document.getElementById("btnClose").click();
      this.chr.detectChanges();
    }
    else {      
      this.isLegendShow='true';      
	    this.speedGaugeObj='true';
      document.getElementById(this.offertmp1).click();
      this.offer = {};
      this.offer['selectedOffer'] = '';
      this.chr.detectChanges();
    }
  }

  private onChangeOffer(event) {
    // alert("onChangeOffer");
     console.log("event.target.value",event.target.value);
   this.offer['selectedOffer'] = event.target.value;  

      //reasionUnlock:any;
      //chkBoxRow:any;
      this.reasionUnlock = event.target.value;


    
  }

  counternum:number=0;
  countBool:boolean=false;
  vol5To6:any;

  private numberWithCommas(column, x, isFooter) {
   //nxk22478 1/7/2020
   /* var number = column.gsknumber ? this.gskNumberPipe.transform(x, []) : x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isNaN(number)) {
    
       if(column.plus) 
       {
      return column.percentage ?  "+ "+number +"%" : number;
    }
    else{
      return column.percentage ?  number +"%" : number;
    }
    } else {
     
      if(column.plus) 
      {
     return column.percentage ?  "+ "+number +"%" : number;
   }
   else{
     return column.percentage ?  number +"%" : number;
   }
    } */
//nxk22478 end 1/7/2020
    var number = column.gsknumber ? this.gskNumberPipe.transform(x, []) : x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isNaN(number)) {
      // Added By Vinay: Started  
      if(column.key=="volume5"){
       
        this.vol5To6=number;
          } 
          if(column.key=="volume6"){
            if(this.vol5To6==1)
            {
              number = "  ";
            }
            
            else{
              if(this.vol5To6.indexOf(",")==-1){             
                number = "1 - "+(this.vol5To6-1);
              }
              else{
                number = "1 - "+(this.vol5To6.replace(/,/g, "")-1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }             
              
            }
          }
     // Added By Vinay: Ended 
       if(column.plus) 
       {       
          return column.percentage ?  "+ "+number +"%" : number;
       }
      else
      {        
                 if(number!=undefined && number.indexOf(",")!=-1 && !isNaN(number.replace(/,/gi, '')) && this.countBool && column.key=="actualvolume")
                        {       
                              if(column.key == 'volume1'){                                    
                                      this.gapVolume=true;      
                                      this.countBool=false;
                              }
                              else{
                                      this.gapVolume=true;                            
                              }          
                        }
                        else{
                              this.gapVolume=false;                         
                        }                         
                        return column.percentage ? number +"%" : number;
        }

      } 
    else {
      // Added By Vinay: Started 
          if(column.key=="volume5"){
            //console.log("Else vkp::"+number);
            this.vol5To6=number;
          } 
          if(column.key=="volume6"){
            if(this.vol5To6==1){
              number = "  ";
            }
            else{
              if(this.vol5To6.indexOf(",")==-1){                
                number = "1 - "+(this.vol5To6-1);
              }
              else{                
                  number = "1 - "+this.vol5To6.split(",")[0]+","+(parseInt(this.vol5To6.split(",")[1])-1);        
                
              }             
              
            }
          }
           // Added By Vinay: Ended  
      if(column.plus) 
      {        
         return column.percentage ?  "+ "+number +"%" : number;
      }
      else
      {    
            
        if(column.key=="actualvolume") {              
              this.gapVolume=true;
        } 
        else
        {              
              this.gapVolume=false;
              this.countBool=true;
        }
          return column.percentage ?  number +"%" : number;
        }

      } 

    }

private OfferId:any; 
    public toOfferUnlock(){
      if(this.offer['selectedOffer']==null)
    {
     //alert(this.translate.instant('labels')['please select offer']);"Please select reason for unlock offer:"
     alert("Please select reason for unlock offer");
      this.clearOffer(event);      
      return true;
    }

     // alert("Offer Unlock"+this.offertmp1);
     // console.log("vkp: ",JSON.parse(sessionStorage.getItem("OfferUnlockChkBoxArr")));
     // alert("OfferUnlockChkBoxArr"+JSON.parse(sessionStorage.getItem("OfferUnlockChkBoxArr")).indexOf(this.offertmp1));
      //console.log("event:::  ");
      this.OfferId = JSON.parse(sessionStorage.getItem("OfferUnlockArrayFinalProcess"))[JSON.parse(sessionStorage.getItem("OfferUnlockChkBoxArr")).indexOf(this.offertmp1)]
      //this.reasionUnlock   // text reason of unlock
      //this.offertmp1  // name of check box for unlock
      // sessionStorage.setItem("OfferUnlockArrayFinalProcess",JSON.stringify(OfferUnlockArrayFinalProcess));
      //sessionStorage.setItem("OfferUnlockChkBoxArr",JSON.stringify(OfferUnlockChkBoxArr));
      //localStorage.getItem("country")
      //console.log("this.OfferId:::"+this.OfferId);
      //console.log("country:::"+localStorage.getItem("country"));
      //console.log("this.reasionUnlock:::"+this.reasionUnlock);
      this.busy = this.headerService.updateOfferUnlockService(this.OfferId, localStorage.getItem("country"),this.reasionUnlock).subscribe(res => {
      //  alert("here"+res);
        this.offerService.fetchTableData();
      });
    }

    private sOLObj:any=[];
  public addToOffer(event) {
    
/* @Vinay: Started- popup issue on offer selection page */
    if(this.offer['selectedOffer']==null)
    {
      alert(this.translate.instant('labels')['please select offer']);
      this.clearOffer(event);      
      return true;
    }
/* @Vinay: Ended- popup issue on offer selection page */

   // console.log("filteredData:::",this.offer['selectedOffer']);
    //console.log("offer_id",this.tableDatas[this.offer['index']]['offer_id' + this.offer['key'].slice(-1)]);

    //return true;
    /*Disabled checkbox on offline mode*/
    if(navigator.onLine == false){
     // var checkbox_rowcount = (event.target.attributes.class.value.trim()).match(/\d+/g)
        Array.from(document.querySelectorAll(".tableRow"+this.checkRowCount)).forEach(e => e.setAttribute('disabled',"true")); 
    }

    this.offerSelect.nativeElement[0].selected = true;
    //var requestParams = "offer_id=" + this.tableDatas[this.offer['index']]['offer_id' + this.offer['key'].slice(-1)]
    //  + "&offer_status=" + this.offer['selectedOffer']
    //  + "&frequency=" + localStorage.getItem("selectedFrq") + "&country=" + localStorage.getItem("country");
      var requestParams = [];
      requestParams.push(["offer_id", this.tableDatas[this.offer['index']]['offer_id' + this.offer['key'].slice(-1)]]);
      requestParams.push(["tab", "pharmacy-offer-summary"]);
      requestParams.push(["country", localStorage.getItem("country")]);
      requestParams.push(["offer_status", this.offer['selectedOffer']]);
      requestParams.push(["frequency", localStorage.getItem("selectedFrq")]);
      var filteredData = requestParams.reduce(function (o, currentArray) {
        var key = currentArray[0], value = currentArray[1]
        o[key] = value
        return o
      }, {});

      this.sOLObj.push(filteredData);   
      localStorage.setItem("pickItem",JSON.stringify(this.sOLObj));



      this.busy = this.headerService.getAggregatedOfferValues(filteredData).subscribe(res => {
      //  alert("here accept"+res);
      this.offerService.fetchTableData();
    });
  
  }

collectAction(item:any, index:any)
{    
  if(0!=item[index].length){ return true;  } 
}

getFAVal(totArr:any, Id:any) {    
  if(totArr!="") {const retFAV =  totArr.map(e => e[Id])
                 .map((e:any, i:any, final:any) => final.indexOf(e) === i && i)
                .filter((e:any) => totArr[e]).map(e=> totArr[e]);
  return retFAV;
  }
  else{
    return null;
  }
}
tempBGArr:any=[];
cArr:any=[];
  ngOnDestroy() {
    // commented for PWA bgSync removal nxk22478
    /*
    if(sessionStorage.getItem("bgb")=="true")
    {
     
     if(JSON.parse(localStorage.getItem("bgSync"))!=null){
      this.tempBGArr = JSON.parse(localStorage.getItem("bgSync"));
     }
      
      if(JSON.parse(localStorage.getItem("pickItem"))!=null || JSON.parse(localStorage.getItem("bgSync"))!=null){
        if(this.tempBGArr.length==0){
          this.cArr = JSON.parse(localStorage.getItem("pickItem"));
        }
        else{
          this.cArr = this.tempBGArr.concat(JSON.parse(localStorage.getItem("pickItem")));
        }
         
        if(this.cArr!=null){

          if(this.cArr.indexOf(null) > -1){
          var cArrR = this.cArr.filter(function (el) {
            return el != null;
          })
          this.cArr = cArrR;
        }         
          localStorage.setItem("bgSync",JSON.stringify(this.getFAVal(this.cArr,'offer_id')));
        }     
            sessionStorage.setItem("bgb","false");      
            localStorage.removeItem("pickItem");           
      }
    }
    */
    //end commented for PWA bgSync removal nxk22478
  }

}