import { Component, OnInit } from '@angular/core';
import { Login } from './login';
import { Router, ActivatedRoute } from '@angular/router';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { apiurl } from '../../assets/data/globals';
import { map } from 'rxjs/operators';
import { OihomeService } from '../services/oihome.service';
import { Observable, Subscription } from 'rxjs';

import { Adal5Service } from 'adal-angular5';

const config = {
  /* tenant: 'kogentix1.onmicrosoft.com', //'usivakoti@kogentix.com',                      
  //clientId: '409f2c10-8ebc-46e8-8b2e-94971a774f84' */
  tenant: 'gsk.onmicrosoft.com', //'usivakoti@kogentix.com',   appidURI   
  //nxk22478             
  //clientId: '4a535ecb-262b-4241-90ce-cb844be835d4' //application id DEV
  //clientId: '37b162e5-6f6e-4a53-8def-e80a5ff5af26' // UAT
  //clientId: 'a55de833-bfef-49b4-8036-ca910d36db3b' //prod
  //clientId: '0b165a8e-aa33-4a41-a4b8-f77ef3bca1f4' //PWADEV
  clientId: '94a1cd71-1164-4064-b3e6-1584c3049340' // New PWA UAT
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [OihomeService]
})

export class LoginComponent implements OnInit {
  model = new Login('', '');
  homeUrl: string;
  loginData: any;
  repcodes: any;
  busy: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private oiService: OihomeService, private adalService: Adal5Service) {
   this.adalService.init(config);
    //nxk22478
  }

  ngOnInit() {
    //alert("onInit");
    // Handle callback if this is a redirect from Azure
    //nxk22478
    this.adalService.handleWindowCallback();
    //localStorage.setItem("repcode", "CDE 1");
    this.homeUrl = "layout/home";

    // Check if the user is authenticated. If not, call the login() method
    //alert(this.adalService.userInfo.authenticated);
    if (!this.adalService.userInfo.authenticated) {
     this.adalService.login();



    } else if (this.adalService.userInfo.authenticated || localStorage.getItem('auth') == "true") {
      if (this.adalService.userInfo.authenticated) {
        this.model.mailid = this.adalService.userInfo.username;
        localStorage.setItem('emailId', this.model.mailid);
        localStorage.setItem('auth', "true");
      }
      if (localStorage.getItem('auth')) {
        this.model.mailid = localStorage.getItem('emailId');
      }

      //nxk22478
      // this.model.mailid = "senthil.r.kumar@gsk.com"; // India Manager
      //this.model.mailid = "shivanagouda.m.goudar@gsk.com"; // India Manager 
      //this.model.mailid = "akash.x.khandelwal@gsk.com"; // India rep
  //   this.model.mailid = "mohit.x.soni@gsk.com"; // INDONESIA rep
     //this.model.mailid ="sabrina.c.ferreira@gsk.com";
     //this.model.mailid ="yovan.x.yorfane@gsk.com"
     //this.model.mailid = "suyog.x.keskar@gsk.com"; // INDONESIA rep
     //this.model.mailid ="manoj.x.r@gsk.com";
      // this.model.mailid ="sagar.x.sarkar@gsk.com";
      //this.model.mailid = "fabiana.x.leal@gsk.com"; // brazil rep
      // this.model.mailid = "alexandre.c.nori@gsk.com"; // brazil admin

      // @Added By Vinay: Started
      localStorage.setItem('emailId', this.model.mailid);
      // @Added By Vinay: Ended
      this.login();
    } else {
      this.adalService.logOut();
    }

    // Log the user information to the console
    // console.log('username ' + this.adalService.userInfo.username);
    // console.log('authenticated: ' + this.adalService.userInfo.authenticated);
    // console.log('name: ' + this.adalService.userInfo.profile.name);
    // console.log('token: ' + this.adalService.userInfo.token);
    // console.log(this.adalService.userInfo.profile);
  }

  login() {
    //console.log("Login functionality");
    //localStorage.setItem('userMailID',this.model.mailId);
    //console.log(this.model.mailId);
    //console.log(this.model.password);
    // alert("INSIDE LOGIN");
    var usermailId = this.model.mailid;
    var currencySymbol;
    // (<HTMLInputElement>document.getElementById("inputuseremail")).value;
    //var password = this.model.password;
    //(<HTMLInputElement>document.getElementById("inputPassword")).value;
    //console.log(usermailId);

    //localStorage.setItem("language","english");

    this.busy = this.oiService.checkLogin(usermailId)
      .subscribe(result => {
        if (result) {
          var jsondata = result; //JSON.parse(result._body);
          var repcodearray = jsondata.rep_codes.split(',');
          localStorage.setItem("username", jsondata.name);
          // console.log(repcodearray);

          //console.log(JSON.stringify(codes));
          localStorage.setItem("repcode", JSON.stringify(repcodearray));
          localStorage.setItem("additional_role", JSON.stringify(jsondata.additional_role));
          localStorage.setItem("reloadDate", jsondata.sale_data_refresh);
          localStorage.setItem("country", jsondata.country);
          if (jsondata.country == "pakistan") {
            currencySymbol = "PKR";
          }
          else if (jsondata.country == "india") {
            currencySymbol = "INR";
          }
          else if (jsondata.country == "brazil") { // nxk22478 10/25
            currencySymbol = "BRL";
          }
          else if (jsondata.country == "turkey") {
            currencySymbol = "TRY";
          }
          else if (jsondata.country == "argentina") {
            currencySymbol = "ARS";
          }
          else if (jsondata.country == "indonesia") {
            currencySymbol = "IDR";
          }
          localStorage.setItem("currencySymbol", currencySymbol);
          //localStorage.setItem("periodType","");
          this.router.navigate([this.homeUrl]);
          //this.router.navigate([this.homeUrl]);                     
        }
      }, error => {
        if (error.status == 500) {
          //alert("Failed to login, please check mailid" + error.status);
        } else if (error.status == 404) {
          //alert("Failed to login, please check mailid " + error.status);
          //       var jsondata =  {
          //         "id": 1,
          //         "name": "Muhammad Mohsin Javed",
          //         "email_id": "muhammadmohsin.x.javed@gsk.com",
          //         "country": "turkey",
          //         "role": "sales_rep",
          //         "rep_codes": "CDE 10,CDE 11",
          //         "sale_data_refresh": "",             
          //     };
          //     var codes = [];
          //     var repcodearray = jsondata.rep_codes.split(',');
          //     localStorage.setItem("repcode",JSON.stringify(repcodearray)); 
          //     localStorage.setItem("username", jsondata.name);
          //     localStorage.setItem("reloadDate", jsondata.sale_data_refresh);
          //     localStorage.setItem("country", jsondata.country);
          //     if(jsondata.country == "pakistan"){
          //       currencySymbol = "PKR";
          // }
          // else if(jsondata.country == "turkey"){
          //   currencySymbol = "TRY";
          // }
          // else if(jsondata.country == "argentina"){
          //   currencySymbol = "ARS";
          // }
          // localStorage.setItem("currencySymbol", currencySymbol);
          //     this.router.navigate([this.homeUrl]);
          //localStorage.setItem("languagelbls", JSON.stringify(languageLbls));
        } else {
          //alert("Failed to login, please check mailid " + error.status);
          //       var jsondata =  {
          //         "id": 1,
          //         "name": "Muhammad Mohsin Javed",
          //         "email_id": "muhammadmohsin.x.javed@gsk.com",
          //         "country": "turkey",
          //         "role": "sales_rep",
          //         "rep_codes": "CDE 10,CDE 11",
          //         "sale_data_refresh": "",             
          //     };
          //     var codes = [];
          //     var repcodearray = jsondata.rep_codes.split(',');
          //     localStorage.setItem("repcode",JSON.stringify(repcodearray)); 
          //     localStorage.setItem("username", jsondata.name);
          //     localStorage.setItem("reloadDate", jsondata.sale_data_refresh);
          //     localStorage.setItem("country", jsondata.country);
          //     if(jsondata.country == "pakistan"){
          //       currencySymbol = "PKR";
          // }
          // else if(jsondata.country == "turkey"){
          //   currencySymbol = "TRY";
          // }
          // else if(jsondata.country == "argentina"){
          //   currencySymbol = "ARS";
          // }
          // localStorage.setItem("currencySymbol", currencySymbol);
          //     this.router.navigate([this.homeUrl]);
          //localStorage.setItem("languagelbls", JSON.stringify(languageLbls));
        }
      });


    //   if (this.model.username === 'Jack' && this.model.password === 'Jack') {
    //    this.router.navigate([this.homeUrl]);
    //    localStorage.setItem('userName',this.model.username);
    //    console.log(localStorage.getItem('userName'));
    //   }else if (this.model.username === 'admin' && this.model.password === 'admin') {
    //     this.router.navigate([this.homeUrl]);
    //     localStorage.setItem('userName',this.model.username);
    //     console.log(localStorage.getItem('userName'));
    //  }

  }
}
