import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { OfferselectionTabComponent } from './offerselection-tab.component';

import { HeaderService } from '../components/header/header.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<OfferselectionTabComponent> {


  constructor(private headerService: HeaderService) { } 

  canDeactivate(
    component: OfferselectionTabComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // you can just return true or false synchronously
    /* var filterData = this.headerService.getSelectedFilterItems();
    return filterData.reduce(function (o, currentArray) {
      var key = currentArray[0], value = currentArray[1];
      if (key === "pharmacy") {
        if (value.length === 0) {
          alert("Please select only one pharmacy.");
          return false;
        } else if (value.length > 1) {
          alert("Please select only one pharmacy.");
          return false;
        }
        return true;
      }
    }, {}); */
    // or, you can also handle the guard asynchronously, e.g.
    // asking the user for confirmation.
    return window.confirm('Discard changes?');
  }
}