import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-offerselection-tab',
  templateUrl: './offerselection-tab.component.html',
  styleUrls: ['./offerselection-tab.component.scss']
})
export class OfferselectionTabComponent implements OnInit {

  public tabFlag = null;

  constructor() {
    this.offerTabClick(null);
  }

  ngOnInit() {

  }

  offerTabClick(e) {
    var applyfilter = localStorage.getItem("applyFilterAction");
    if (applyfilter == "true") {
      if (!!e) {
        localStorage.setItem("offerselectedTab", "");
        localStorage.setItem("offerselectedTab", e.target.title.toLowerCase());
      }
      var aTab = localStorage.getItem("offerselectedTab");
      if (aTab == "summary view") {
        this.tabFlag = true;
      } else {
        this.tabFlag = false;
      }
    } else {
      if (localStorage.getItem("offerselectedTab") == "summary view") {
        this.tabFlag = true;
      } else {
        this.tabFlag = false;
      }
    }
  }  
}