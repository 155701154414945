import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HeaderService } from '../../components/header/header.service';
import { Router } from '@angular/router';
import { OfferService } from './offerselection-new-grid-service';
import { debounceTime } from 'rxjs/operators';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AfterViewInit, ElementRef, ViewChild } from '@angular/core';
declare var jQuery: any;
declare var $: any;

import 'rxjs/add/operator/map';

@Component({
  selector: 'app-offerselection-new-grid',
  templateUrl: './offerselection-new-grid.component.html',
  styleUrls: ['./offerselection-new-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OfferselectionNewGridComponent implements OnInit, OnDestroy {
  private gridSubscription: Subscription;
  aggregateData: any = {};
  gridData: any = {};
  footerData: any = {};
  busy: Subscription;
  counter: number = 0;
  counterbool: boolean = false;
  countInd: number = 7;
  countbrazil: number = 11;
  agreement: boolean = false;

  @ViewChild("busyone", { static: false }) busyone: ElementRef;

  constructor(private router: Router, private headerService: HeaderService, public offerService: OfferService, private changeDetectorRef: ChangeDetectorRef, private http: HttpClient) {
    localStorage.setItem("offerselectedTab", "Summary View");
    this.busy = this.gridSubscription = this.offerService.getTableData().subscribe(data => {
      this.gridData = data;
      this.footerData = data;
      this.aggregateData = data;
      this.changeDetectorRef.markForCheck();
    }, error => console.log(error));

    this.headerService.lngSummaryGridTableData.pipe(debounceTime(500)).subscribe(() => {
      this.offerService.fetchTableData();
    });

  }

  ngOnInit() {
    //setTimeout(this.removeLib, 15000);
    //elem.style.display = 'none';
    this.offerService.fetchTableData();
    if (localStorage.getItem("country") != "india") {
      $('#history').hide();
    } else {
      localStorage.setItem("activetab", "pharmacy-offer-summary");
    }
    //lib-ng-busy
    if (localStorage.getItem("country") == 'indonesia') {
      this.fetchAgreementStatus();
    } else {
      $('#agreement').hide();
    }
  }

  ngOnDestroy() {
    this.gridSubscription.unsubscribe();
    this.counterbool = false;
  }

  changeStatus() {
    this.headerService.insertEAgreementStatus().subscribe
      (status => {
        document.getElementById("agreementID").setAttribute('disabled', 'true');
      });
  }

  fetchAgreementStatus() {
    this.headerService.getEAgreementStatus().subscribe
      (status => {
        this.agreement = status;
        if (status) {
          document.getElementById("agreementID").setAttribute('disabled', 'true');
          $("#agreementID").prop("checked", true);
          $('#agreementID').css({"disabled":"red"});
        } else {
          document.getElementById("agreementID").removeAttribute('disabled');
          $("#agreementID").prop("checked", false);
        }
      });
  }

  private closeagreement(event) {
    $("#agreementID").prop("checked", false);
  }

  ngWaitWatchInit() {
    if (localStorage.getItem("country") == 'india') {
      setTimeout(() => {
        this.busyone.nativeElement.style.visibility = "hidden";
      }, 5000);
    }
    else if (localStorage.getItem("country") == 'brazil') {
      setTimeout(() => {
        //console.log(this.busyone.nativeElement.innerText);
        //console.log("==================>",this.busyone.nativeElement);
        this.busyone.nativeElement.style.visibility = "hidden";
      }, 0);
    }
    // nxk22478 19 Jan 2021 IDN
    else if (localStorage.getItem("country") == 'indonesia') {
      setTimeout(() => {
        this.busyone.nativeElement.style.visibility = "hidden";
      }, 5000);
    }
    // end here
  }

  ngAfterViewChecked() {
    //console.log("country::"+localStorage.getItem("country"));
    //localStorage.getItem("country");
    //console.log("==>"+this.counter);
    this.counter++;
    if (localStorage.getItem("country") == 'india') {
      if (this.counter > this.countInd) {
        if (!this.counterbool) {
          this.ngWaitWatchInit();
          this.counterbool = true;
        }
      }
    }
    else if (localStorage.getItem("country") == 'brazil') {
      if (this.counter > this.countbrazil) {
        if (!this.counterbool) {
          this.ngWaitWatchInit();
          this.counterbool = true;
        }
      }
    }
    // nxk22478 19 Jan 2021 IDN
    else if (localStorage.getItem("country") == 'indonesia') {
      if (this.counter > this.countInd) {
        if (!this.counterbool) {
          this.ngWaitWatchInit();
          this.counterbool = true;
        }
      }
    }
    // end here 

  }

}