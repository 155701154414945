import { Pipe, PipeTransform } from '@angular/core';
// import { OihomeService } from '../services/oihome.service';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any[] {
    if(!items){ return []; }
    return items.filter(it => it[field] === value);
  }
}
