export class PrioritizationGridModel {
    constructor(){};
    public offerStatus: string;
    public pharmacyName:string;
    public brands : BrandInfo[];
    public status: string;
}

export class BrandInfo{
    public brandName:string;
    public baselineProjection : string;
    public incrementalProjection : string
    public roi : string;
}