import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gskNumber'
})

export class GskNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var decimalVal;
    decimalVal = parseFloat(value).toFixed(1);
   /* if (localStorage.getItem("country")=="india"){
       decimalVal = parseFloat(value).toFixed(1);
    }else{
       decimalVal = parseFloat(value).toFixed(2);
    }*/
    if (localStorage.getItem("country")=="indonesia"){
      decimalVal = parseFloat(value).toFixed(2);
   }
    var decimalLIst = decimalVal.split('.')
    decimalLIst[0] = decimalLIst[0].length > 1 ? Number(decimalLIst[0]).toLocaleString('en') : "0" + decimalLIst[0];
    return decimalLIst.join('.');
  }
}
