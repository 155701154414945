import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
// import { ChartModule } from 'angular2-highcharts';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslationService } from './services/TranslationService';

import { AppRoutingModule } from './app-routing.module';
// import { BusyModule } from 'angular2-busy';

import { AppComponent } from './app.component';
// import { OihomeService } from './services/oihome.service';
import { FilterPipe } from './shared/filter.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { LoginComponent } from './login/login.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SearchPipe } from './search.pipe';
import { CanDeactivateGuard } from './layout/offerselection-tab/offerselection-route-gaurd';
import { AuthenticationGuard } from './authentication-guard';
import { OfferselectionTabModule } from './layout/offerselection-tab/offerselection-tab.module';
import { OfferService } from './layout/offerselection-tab/offerselection-new-grid/offerselection-new-grid-service';
import { HeaderService } from './layout/components/header/header.service';
// import { httpLoaderFactory } from './services/httpLoaderFactory';
import { TranslateService } from '@ngx-translate/core';
import { GskNumberPipe } from './gsk-number.pipe';
// import { EventProvider } from './services/event-provider';

import { AppInterceptor } from './app-interceptor';

import { Adal5Service, Adal5HTTPService } from 'adal-angular5';
import { NgBusyModule } from 'ng-busy';
import { PagerService } from './layout/pharmacies-gird/pgerservice.service';
import { DataTableModule } from 'ng-angular8-datatable';
// import {NgxPaginationModule} from 'ngx-pagination';
import {OfferRollBackService} from '../app/layout/offer-roll-back/offer-roll-back.service';
import { OnlineStatusComponent } from'../app/online-status/online-status.component'; //mxb11725


declare var require: any;

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    LoginComponent,
    OnlineStatusComponent,  // mxb11725
    GskNumberPipe   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgPipesModule,
    BrowserAnimationsModule,
    //BusyModule,
    //HttpModule,
    DataTableModule,
    FormsModule,
    HttpClientModule,
    OfferselectionTabModule,
    NgBusyModule,   
    //NgxPaginationModule,
    //ChartModule,    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        //useFactory : HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
      CanDeactivateGuard,
      OfferRollBackService, 
      OfferService, 
      HeaderService, 
      TranslateService, 
      Adal5Service, 
      PagerService,
      { provide:HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
      { provide: Adal5HTTPService, useFactory: Adal5HTTPService.factory, deps: [HttpClient, Adal5Service] },    
      AuthenticationGuard    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
