import { Injectable } from '@angular/core';
import { Response, RequestOptions, ResponseContentType } from '@angular/http';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../../../assets/data/globals';
@Injectable()
export class OfferRollBackService {

  constructor(private http: HttpClient) { }

  customerGroupData:any;
  
  /** 
   * @Vinay (Started): Offer RollBack Service data for Veeva/Client Group Data Search
   * 
  */
 
getCustomergroup(enterClient:any,countryName:any) {
  
 //console.log("enterClient::------"+enterClient+"    countryName::----"+countryName);
 var customerGroupURI:any;

 if(countryName=='brazil'){
  
      customerGroupURI = apiurl + "api/getCustGrpBzlInfo?customer_group="+enterClient;
     // console.log("customerGroupURI::: "+customerGroupURI);
 }
 if(countryName=='india'){
  customerGroupURI = apiurl + "api/getPharmacyIndInfo?veeva_code="+enterClient;
 }
      return this.http.get(customerGroupURI, {}).map((data: Response) => {
          this.customerGroupData = data;
        //  alert(data);
          return this.customerGroupData;
      },
        err => {
          alert('failed to get SKU data');
        });
    }
        
  /* 
   * @Vinay (Ended): Offer RollBack Service data for (Veeva/Client Group) Data Search
   * 
  */

  /*
   * @Vinay (Started): Offer RollBack Service for update sku from Veeva/Client Group
   * 
  */
 updateSKU(cgName:any,skuValues:any,countryName:any) {
     
      var updateSKUURI:any;
      
      if(countryName=='brazil'){
      //  alert("df");
      updateSKUURI = apiurl + "api/updCustGrpSkuBzlInfo?customer_group="+cgName+"&sku="+skuValues+"&email_id="+localStorage.getItem('emailId');
      console.log("updateSKUURI::", updateSKUURI);
      }
      else if(countryName=='india'){
      updateSKUURI = apiurl + "api/updPharmacySkuIndInfo?veeva_code="+cgName+"&sku="+skuValues+"&email_id="+localStorage.getItem('emailId');;
      }

      return this.http.get(updateSKUURI, {}).map((data: Response) => {           
          return data;
      },
        err => {
          alert('failed to update SKU data');
        });
    }

/** 
   * @Vinay (Ended): Offer RollBack Service for update sku from Veeva/Client Group
   * 
  */
}
