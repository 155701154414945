import { Injectable, Output, EventEmitter } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
// import { Subject } from 'rxjs/Subject';
// import { Observable } from 'rxjs';
import { apiurl } from '../../assets/data/globals';
import 'rxjs/add/operator/map';

// import { FilterPipe } from '../shared/filter.pipe'

@Injectable()
export class OihomeService {
  loginData: any;

  constructor(private http: HttpClient) { }

  checkLogin(emailid) {
    const loginUrl = apiurl + "api/getUserInfo?email_id=" + emailid;
    // let segmentURL = apiurl + "api/getGenericFilterData?repCode=" + localStorage.getItem("repcode") + "&filtername=segment";
    return this.http.get(loginUrl, {}).map(
      (data: Response) => {
        if (data) {
          this.loginData = data;
          return this.loginData;
        }
        else if (data.status === 500) {
          console.log('server error occured with status 500'+ data.status);  
        } else {
          console.log('server error occured with status'+ data.status);
        }
      },
      err => {
        console.log('Login Failed'+err);
        //alert('login failed ' + err);
      });
  }
 

}
