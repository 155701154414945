import { Component,Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
//import { HeaderService } from '../components/header/header.service';
import { HeaderService } from '../layout/components/header/header.service';
//import { OfferService } from '../offerselection-new-grid/offerselection-new-grid-service';
import { OfferService } from '../layout/offerselection-tab/offerselection-new-grid/offerselection-new-grid-service';
//import { GridComponent } from './layout/offerselection-tab/grid/grid.component';
//import { GridComponent } from './layout/offerselection-tab/grid/grid.component';
import {GridComponent } from '../layout/offerselection-tab/grid/grid.component';

@Component({
  selector: 'app-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.css']
})
export class OnlineStatusComponent implements OnInit {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  @Input() onlineStatusMessage: string;
  @Input() onlineStatus: string;
  subscriptions: Subscription[] = [];
  busy: Subscription;
  @ViewChild(GridComponent, {static: false}) gridComponent: GridComponent;

  constructor(private headerService: HeaderService, private offerService: OfferService, ) { }

  ngOnInit() {     
    if(navigator.onLine == true){   
   
    }
  }

  eSKUs(array, params){
    array.some(function(item, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }

  rArrVal:any=[];
   
  bSS(aPoint){
   try{     
     if(JSON.parse(localStorage.getItem(aPoint))!=null && JSON.parse(localStorage.getItem(aPoint))!=false){
        
    JSON.parse(localStorage.getItem(aPoint)).forEach(element => {
      this.busy = this.headerService.getAggregatedOfferValues(element).subscribe(res => {      
        this.rArrVal = JSON.parse(localStorage.getItem(aPoint));
        this.eSKUs(this.rArrVal, {
          key: 'offer_id',
          value: element.offer_id
        });
        localStorage.setItem(aPoint,JSON.stringify(this.rArrVal));
       
        this.offerService.fetchTableData();
      });
    }) 
  }
  
  } catch(e){console.log("Error On Offline Call",e);} 

  }

  bSSH(){    
    // commented for PWA bgsync removal code nxk22478  
  /*  if(localStorage.getItem("bgSync")!=null)
    {    
      this.bSS("bgSync");
    }
    if(localStorage.getItem("pickItem")!=null){     
      this.bSS("pickItem");
    } */

    //end here commented for PWA bgsync removal code nxk22478  
  }
 
}

