import { Injectable, Output, EventEmitter } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
declare var google: any;
declare var MarkerClusterer: any;
import { apiurl } from '../../../../assets/data/globals';
//import { forEach } from '@angular/router/src/utils/collection';
//import { forEach } from '@angular/router';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
//import { GlobalEventsManager } from '../../../services/oihome.service';
import { PrioritizationGridModel, BrandInfo } from '../../prioritization-pharms-grid/prioritizationgridModel';
import "rxjs/Rx";

@Injectable()
export class HeaderService {

  @Output() lngChange: EventEmitter<boolean> = new EventEmitter();
  @Output() lngChangeCurrentSelection: EventEmitter<boolean> = new EventEmitter();
  @Output() lngloadBasicTableData: EventEmitter<boolean> = new EventEmitter();
  @Output() lngSummaryGridTableData: EventEmitter<boolean> = new EventEmitter();

  private messageSource = new BehaviorSubject('');
  currentmessage = this.messageSource.asObservable();
  filterData: any;
  rowData: any;
  public pharmaciesData: any;
  public prioritizationData: any;
  public offerselectionData: any;
  homegridData: any;
  public markers: any[] = [];
  public offerstatusData: any;
  public segementData: any;
  public citybrickData: any;
  public brandData: any;
  public therepyData: any;
  public channelData: any;
  public pharmacyData: any;
  public baseTableOffers: any;
  public unlockResponse: any;
  public offersatuschangeData: any;
  public allPharmaOffersData: any;
  activeTab: any;
  gridData: any = [];
  public myModelObjList: PrioritizationGridModel[];
  public genericFilterData: any;
  basispotentialData: any;
  projectionchartData: any;
  public chartsData: any;
  public currentFilterData: any = [];
  public applyFilterAction: any;
  prioritizationExportData: any;
  country: any;
  languages: any = [];

  //private mymap: any;
  constructor(private http: HttpClient) {
    this.country = localStorage.getItem("country");
  }

  changeMessage(pharmacyData: any) {
    this.messageSource.next(pharmacyData);

  }

  setFilterData(data) {
    this.filterData = data;
  }

  getFilterData() {
    return this.filterData;
  }

  setRowData(data) {
    this.rowData = data;
  }

  getRowData() {
    return this.rowData;
  }

  getApplyFilterData() {
    return this.currentFilterData;
  }

  setApplyFilterData(applyFilterData) {
    this.currentFilterData = applyFilterData;
  }

  getSegments() {
    let segmentURL = apiurl + "api/getGenericFilterData?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&filtername=segment";
    return this.http.get(segmentURL, {}).map(
      (data: Response) => {
        //console.log(data); 
        if (data.status == 200) {
          this.segementData = data;
          return this.segementData;
        }
        else if (data.status == 500) {
          alert("server error occured with status " + data.status);
        } else {
          alert("server error occured with status " + data.status);
        }
      },
      err => {
        alert('failed to get segment data');
      });
  }



  getCitybrickData() {
    let citybrickURL = apiurl + "api/getGenericFilterData?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&filtername=city-brick";
    return this.http.get(citybrickURL, {}).map(
      (data: Response) => {
        this.citybrickData = data;
        return this.citybrickData;
      },
      err => {
        alert('failed to get cityBrick data');
      });
  }

  setChartsData(chartsData1) {
    this.chartsData = chartsData1;
  }

  getChartsData() {
    return this.chartsData;
  }


  getBasisOfPotentialsData(data) {
    let basisofpotentialurl = apiurl + "api/getOffersBasisOfPotential?country:" + localStorage.getItem("country");
    return this.http.post(basisofpotentialurl, data, {}).map(
      data => {
        this.basispotentialData = data;
        return this.basispotentialData;
      },
      err => {
        alert('failed');
      });
  }

  getProjectionChartData(data) {
    let projectioCharturl = apiurl + "api/getOffersProjectionChart?country:" + localStorage.getItem("country");
    return this.http.post(projectioCharturl, data, {}).map(
      data => {
        this.projectionchartData = data;
        return this.projectionchartData;
      },
      err => {
        alert('failed');
      });
  }

  getBrandData() {
    let brandURL = apiurl + "api/getGenericFilterData?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&filtername=brand";
    return this.http.get(brandURL, {}).map(
      (data: Response) => {
        this.brandData = data;
        return this.brandData;
      },
      err => {
        alert('failed to get brand data');
      });
  }


  getTherephyData() {
    let therepyURL = apiurl + "api/getGenericFilterData?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&filtername=therapy";
    return this.http.get(therepyURL, {}).map(
      (data: Response) => {
        //console.log(data);  
        this.therepyData = data;
        //console.log(this.therepyData);
        return this.therepyData;
      },
      err => {
        alert('failed to get therepy data');
      });

  }

  getChannelData() {
    let channelURL = apiurl + "api/getGenericFilterData?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&filtername=channel";
    return this.http.get(channelURL, {}).map(
      (data: Response) => {
        //console.log(data);  
        this.channelData = data;
        //console.log(this.channelData);
        return this.channelData;
      },
      err => {
        alert('failed to get channel data');
      });
  }

  getPharmacyData() {
    let channelURL = apiurl + "api/getGenericFilterData?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&filtername=pharmacy";
    return this.http.get(channelURL, {}).map(
      (data: Response) => {
        //console.log(data);  
        this.pharmacyData = data;
        //console.log(this.channelData);
        return this.pharmacyData;
      },
      err => {
        alert('failed to get pharmacy data');
      });
  }

  getSelectedFilterItems() {
    let selectedSegments = [];
    let selectedCityBricks = [];
    let selectedBrands = [];
    let selectedChannels = [];
    let selectedTherepy = [];
    let selectedPharmacies = [];
    let selectedFrequency = [];
    var filters = [];

    var ckeckedSegements = $("#segmentChk input[type='checkbox']:checked").not("[value='Select All']");
    // console.log("ckeckedSegements.length=", ckeckedSegements.length);
    var checkedCityBricks = $("#citybrickChk input[type='checkbox']:checked").not("[value='Select All']");
    var checkedBrands = $("#brandcheckdiv input[type='checkbox']:checked").not("[value='Select All']");
    var checkedChannels = $("#chanelcheckdiv input[type='checkbox']:checked").not("[value='Select All']");
    var checkedTherepys = $("#therepycheckdiv input[type='checkbox']:checked").not("[value='Select All']");
    var checkedpharmacies = $("#pharmacycheckdiv input[type='checkbox']:checked").not("[value='Select All']");

    //console.log(ckeckedSegements + "..... " +checkedCityBricks+ "-----" +checkedTherepys + "------   " +checkedpharmacies +" ------ " +checkedBrands);  

    for (var i = 0; i < ckeckedSegements.length; i++) {
      var element = <HTMLInputElement>ckeckedSegements[i];
      // console.log(element.value);
      selectedSegments.push(element.value);
    }

    for (var i = 0; i < checkedCityBricks.length; i++) {
      var element = <HTMLInputElement>checkedCityBricks[i];
      //console.log(element.value);        
      selectedCityBricks.push(element.value);
    }

    for (var i = 0; i < checkedBrands.length; i++) {
      var element = <HTMLInputElement>checkedBrands[i];
      //console.log(element.value);        
      selectedBrands.push(element.value);
    }

    for (var i = 0; i < checkedChannels.length; i++) {
      var element = <HTMLInputElement>checkedChannels[i];
      selectedChannels.push(element.value);
    }

    for (var i = 0; i < checkedTherepys.length; i++) {
      var element = <HTMLInputElement>checkedTherepys[i];
      selectedTherepy.push(element.value);
    }
    for (var i = 0; i < checkedpharmacies.length; i++) {
      var element = <HTMLInputElement>checkedpharmacies[i];
      selectedPharmacies.push(element.value);
    }

    /* let pharmacycode = localStorage.getItem('pharmacyname-code');
     //alert(pharmacycode);
     if(pharmacycode) {
       //alert('if null why inside');
       selectedPharmacies = []; 
       selectedPharmacies.push(pharmacycode);
     } */

    selectedFrequency.push(localStorage.getItem("selectedFrq"));
    filters.push(["repCode", JSON.parse(localStorage.getItem("repcode"))], ["segment", selectedSegments], ["city-brick", selectedCityBricks], ["brand", selectedBrands], ["channel", selectedChannels], ["therapy", selectedTherepy], ["pharmacy", selectedPharmacies], ["frequency", selectedFrequency]);
    //filters.push(["repCode", JSON.parse(localStorage.getItem("repcode"))], ["segment", selectedSegments], ["city-brick", selectedCityBricks], ["brand", selectedBrands], ["channel", selectedChannels], ["therapy", selectedTherepy], ["pharmacy", selectedPharmacies]);
    return filters;
  }


  getGenericFilterData(currentFilter) {
    var filterDataoutput = this.getSelectedFilterItems();
    //console.log(filterDataoutput);
    filterDataoutput.push(["current_filter", [currentFilter]]);
    filterDataoutput.push(["country", [localStorage.getItem("country")]]);
    filterDataoutput.push(["frequency", [localStorage.getItem("selectedFrq")]]);
    var filtes = filterDataoutput.reduce(function (o, currentArray) {
      var key = currentArray[0], value = currentArray[1]
      o[key] = value
      return o
    }, {});

    let genericFilterUrl = apiurl + "api/getGenericFilteredData";
    return this.http.post(genericFilterUrl, filtes, {}).map(
      data => {
        this.genericFilterData = data;
        return this.genericFilterData;
      },
      err => {
        alert('failed');
      });
  }

  getUpdatedFiltersData(currentFilter) {
    var filterDataoutput = this.getSelectedFilterItems();
    filterDataoutput.push(["current_filter", [currentFilter]]);
    filterDataoutput.push(["country", [localStorage.getItem("country")]]);
    filterDataoutput.push(["frequency", [localStorage.getItem("selectedFrq") || "Quarterly"]]);
    var filtes = filterDataoutput.reduce(function (o, currentArray) {
      var key = currentArray[0], value = currentArray[1]
      o[key] = value
      return o
    }, {});
    sessionStorage.removeItem("applyFillterStore");
    //let genericFilterUrl = apiurl + "api/filterSideMenuData";
    //return this.http.post(genericFilterUrl, filtes,{}).map( // for post api nxk22478
    let genericFilterUrl = apiurl + "api/getFilterSideMenuData?repCode=" + JSON.parse(localStorage.getItem("repcode")) +
      "&country=" + localStorage.getItem("country") + "&current_filter=" + currentFilter;
    console.log("genericFilterUrl****" + genericFilterUrl);
    return this.http.get(genericFilterUrl, {}).map(
      data => {
        this.genericFilterData = data;
        return data;
      },
      err => {
        alert('failed');
      });
  }

  applayFilter(filterData) {
    this.currentFilterData = filterData;
    // alert(filterData);
    sessionStorage.setItem("applyFillterStore", filterData);
    //alert("Hello"+filterData);
    let url = apiurl + "api/applyGenericFilterOnPharmacies";
    return this.http.post(url, filterData, {}).map( // nxk22478 commented 3/14/20
      //return this.http.get(url, {} ).map( 
      data => {
        this.pharmaciesData = data;
        return this.pharmaciesData;
      },
      err => {
        alert('failed');
      });
  }


  applayFilter2() {
    var offerStatus = localStorage.getItem('offerStatus');

    if (offerStatus == null) {
      console.log('offer Status....' + offerStatus);
      //localStorage.setItem('offerStatus','All');
    }
    let url = apiurl + "api/filterPharmaciesByOfferStatus?country=" + localStorage.getItem("country") + "&tab=" + localStorage.getItem("activetab") + "&frequency=" + localStorage.getItem("selectedFrq") + "&repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&offerStatus=" + localStorage.getItem("offerStatus");
    //let url = apiurl + "api/applyGenericFilterOnPharmacies?country=" + localStorage.getItem("country");

    //let offerSatusURL = apiurl + "api/filterPharmaciesByOfferStatus?repName=Jack&offerStatus=" + offerstatus;
    //let url  = apiurl + "api/filterPharmaciesByOfferStatus?country=india&tab=home&frequency=Quarterly&repCode=NS379605&offerStatus='All'";
    //let url = apiurl + "api/applyGenericFilterOnPharmacies?country=" + localStorage.getItem("country");
    return this.http.get(url, {}).map(
      (data: Response) => {
        if (data) {
          this.pharmaciesData = data;
          return this.pharmaciesData;
        }
      },
      err => {
        alert('failed ....');
      });
  }


  /*getPharmacyOfferSelectionData(filterData) {
    this.currentFilterData = filterData;
    let url = apiurl + "api/getPharmacyOfferSelectionData";
    return this.http.post(url, filterData, {}).map(
      data => {
        this.pharmaciesData = data;
        return this.pharmaciesData;
      },
      err => {
        alert('failed');
      });
  }*/

  // newley added for PWA GET request 
  getPharmacyOfferSelectionData(filterData) {
    console.log("getPharmacyOfferSelectionData method");
    this.currentFilterData = filterData;
    //http://localhost:8443/api/getPharmacyOfferSelectionData?country=india&repCode=NS379605&frequency=Quarterly&pharmacy=0015800001Wtzk6AAB-aakash medicos&tab=pharmacy-offer-summary   
    let url = apiurl + "api/getPharmacyOfferSelectionData?country=" + localStorage.getItem("country") + "&tab=pharmacy-offer-summary&frequency=" +
      localStorage.getItem("selectedFrq") + "&pharmacy=" + encodeURIComponent(localStorage.getItem("pharmacy-code"))
      /* commented this section for passing obly veeva id or pharmacy id
       encodeURIComponent(localStorage.getItem("pharmacyname-code"))*/ +
      "&segment=" + localStorage.getItem("segment") + "&city-brick=" + localStorage.getItem("city-brick") +
      "&brand=" + localStorage.getItem("brand") + "&repCode=" + JSON.parse(localStorage.getItem("repcode"));


    console.log('url is ' + url);
    return this.http.get(url, {}).map(
      (data: Response) => {
        if (data) {
          this.pharmaciesData = data;
          return this.pharmaciesData;
        }
      },
      err => {
        alert('failed ....');
      });
  }
  //nxk22478
  getAllPharmaOffers(apiURL) {

    return this.http.get(apiURL, {}).map(
      (data: Response) => {
        console.log(apiURL);
        if (data.status == 200) {
          this.allPharmaOffersData = data;
          return this.allPharmaOffersData;
        }
        else if (data.status == 500) {
          alert("server error occured with status " + data.status);
        } else {
          alert("server error occured with status " + data.status);
        }
      },
      err => {
        alert('failed to get segment data');
      });
  }



  prepareRowData(data) {
    //console.log(data);
    var genericMappingData = data.genericMappingData;
    var offerAggregationData = genericMappingData.offerAggregationData;
    var pharmacies = data.pharmacies;
    //console.log(pharmacies);
    var pharmacyName;
    var offerStatus;
    var incrementalVal;
    let dummyData = [];
    for (var i = 0; i < pharmacies.length; i++) {
      pharmacyName = pharmacies[i].name;
      for (var p = 0; p < pharmacies[i].products.length; p++) {
        for (var s = 0; s < pharmacies[i].products[p].skuDetails.length; s++) {
          for (var o = 0; o < pharmacies[i].products[p].skuDetails[s].offerDetails.length; o++) {
            offerStatus = pharmacies[i].products[p].skuDetails[s].offerDetails[o].status;
            //console.log(offerStatus);
            incrementalVal = pharmacies[i].products[p].skuDetails[s].offerDetails[o].increment_value;
            dummyData.push({ name: pharmacyName, offer_status: offerStatus, increment_value: incrementalVal });
            //this.gridOptions.rowData = dummyData; 

          }
        }
      }
    }
    //console.log(dummyData);
    this.setHomeGridData(dummyData);
    return dummyData;
  }

  setHomeGridData(data) {
    this.homegridData = data;
  }
  getHomeGridData() {
    return this.homegridData;
  }

  getPrioritizationData(filtData) {
    let url = apiurl + "api/getPrioritizationOfferCategoryPhamracies";
    return this.http.post(url, filtData, {}).map(
      data => {
        this.prioritizationData = data;
        return this.prioritizationData;
      },
      err => {
        alert('failed');
      });
  }

  preparePrioritizationGridRowData(data) {
    //pharmacyLevelDataList
    // console.log(data);
    var pharmacyLevelDataMap = data.pharmacyLevelDataMap;
    var objLength = Object.keys(pharmacyLevelDataMap).length;
    //data.pharmacyLevelDataList;
    // console.log(pharmacyLevelDatalist);
    var offerstatus;
    var pharmacyName;
    var brandName;
    var baselineProjection;
    var incrementalProjection;
    var roi;
    let rowData = [];
    var prioritizedAggrData = data.genericMappingData.prioritizedAggregationData;
    //console.log(pharmacyLevelDataMap);
    var offerTypes = [
      "offers_not_yet_extended",
      "duration_about_to_mature",
      "offer_extended_not_included",
      "offers_rejected"
    ];

    offerTypes.forEach(function (el) {
      //console.log("******")
      //console.log(pharmacyLevelDataMap[el]);
      var groupByOfferData = pharmacyLevelDataMap[el];
      if (groupByOfferData && groupByOfferData.length != 0) {

        for (var i = 0; i < groupByOfferData.length; i++) {
          offerstatus = groupByOfferData[i].offerStatus;
          //console.log(offerstatus);
          pharmacyName = groupByOfferData[i].pharmacyName;
          var brandAggregatedDetails = groupByOfferData[i].brandAggregatedDetails;
          //console.log(brandAggregatedDetails.length);
          for (var j = 0; j < brandAggregatedDetails.length; j++) {
            brandName = brandAggregatedDetails[j].brand_name;
            baselineProjection = brandAggregatedDetails[j].baseLine_projection;
            incrementalProjection = brandAggregatedDetails[j].incremental_projection;
            roi = brandAggregatedDetails[j].roi_in_percentage;
            rowData.push({ offerstatus: offerstatus, pharmacy: pharmacyName, brand_name: brandName, baseLine_projection: baselineProjection, incremental_projection: incrementalProjection, roi_in_percentage: roi })
          }
        }
      }
    });
    return rowData;
  }

  showPrioritizationAggData(prioritizedAggrData) {
    //console.log(prioritizedAggrData);
    document.getElementById("dureationcardtext").innerText = prioritizedAggrData.duration_about_to_mature;
    document.getElementById("offerextendcardtext").innerText = prioritizedAggrData.offer_extended_not_concluded;
    document.getElementById("notyetextendcardtext").innerText = prioritizedAggrData.offers_not_yet_extended;
    //document.getElementById("rejectcardtext").innerText = prioritizedAggrData.offers_rejected;
  }


  createMapMarkerForHomeMap(data, from): void {
    var mymap;
    if (this.country == "pakistan") {
      mymap = new google.maps.Map(document.getElementById('homemap'), {
        zoom: 8,
        center: { lat: 41.0082, lng: 28.9784 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }
    else if (this.country == "india") {
      // console.log("create map for turkey  " + this.country);
      mymap = new google.maps.Map(document.getElementById('homemap'), {
        zoom: 8,
        center: { lat: 20.5937, lng: 78.9629 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }
    else if (this.country == "turkey") {
      // console.log("create map for turkey  " + this.country);
      mymap = new google.maps.Map(document.getElementById('homemap'), {
        zoom: 8,
        center: { lat: 38.9637, lng: 35.2433 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }
    else if (this.country == "argentina") {
      mymap = new google.maps.Map(document.getElementById('homemap'), {
        zoom: 8,
        center: { lat: 33.9391, lng: 67.7100 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }
    let icon;
    let marker;
    let locationCount;
    var bounds = new google.maps.LatLngBounds();
    let latLng;
    let lat;
    let lng;
    let pharmsName;
    let offerStatus = "";

    for (let i = 0; i < data.tableData.length; i++) {
      lat = data.tableData[i].latitude;
      lng = data.tableData[i].longitude;
      offerStatus = data.tableData[i].offer_status;

      if (offerStatus.indexOf("Offer accepted") != -1) {
        icon = "assets/img/mm_20_green.png";
      } else if (offerStatus.toLowerCase().indexOf("rejected") != -1) {
        icon = "assets/img/mm_20_red.png";
      } else if (offerStatus.indexOf("Offer extended, response awaited") != -1) {
        icon = "assets/img/mm_20_orange.png";
      } else if (offerStatus.indexOf("Offer not yet extended") != -1) {
        icon = "assets/img/mm_20_blue.png"
      }

      latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
      pharmsName = data.tableData[i].name;
      //console.log(icon);
      marker = new google.maps.Marker({
        map: mymap,
        zoom: 16,
        //center: new google.maps.LatLng(2.8,-187.3),
        icon: icon,
        position: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
        title: pharmsName
      });
      bounds.extend(marker.getPosition());
      //var bounds = new google.maps.LatLngBounds();
      this.markers.push(marker);
      // for (var b = 0; b < this.markers.length; i++) {
      //   bounds.extend(this.markers[i].getPosition());
      //  }
      marker.setMap(mymap);
    }

    mymap.fitBounds(bounds);
  }


  buildprioritizationTable(data) {
    //console.log("data", data);
    var gridMainData = [];
    if (data.length > 0) {
      var res = alasql('SELECT offerstatus, SUM(*) AS pcount FROM ? GROUP BY offerstatus', [data]);
      this.gridData = [];
      //console.log("res", res);
      res.forEach(function (el) {
        var groupByStatus = { offerstatus: "", items: [] };
        groupByStatus.offerstatus = el.offerstatus;
        groupByStatus.items = alasql('SELECT *  FROM ? WHERE offerstatus=?', [data, el.offerstatus]);
        //console.log("groupByStatus=", groupByStatus);
        gridMainData.push(groupByStatus);
      });
    }
    //console.log("gridMainData", gridMainData);

    var pharmacyGridBody = document.getElementById("prioritizationGridBody");
    var datarow = "";
    pharmacyGridBody.innerHTML = "";
    gridMainData.forEach(function (row) {
      datarow = "<div class='mtableRow  col-md-12' >";
      datarow += "<div class='col-md-2 mtableCell text-center'>";
      var offerStatusString = "";
      if (row.offerstatus.toLowerCase() == "duration_about_to_mature") {
        offerStatusString = "<div class=" + 'durationabouttomature' + "></div>";
      } else if (row.offerstatus.toLowerCase() == "offer extended not included") {
        offerStatusString = "<div class=" + 'offerextendednotincluded' + "></div>";
      } else if (row.offerstatus.toLowerCase() == "offer not yet extended") {
        offerStatusString = "<div class=" + 'offernotyetextended' + "></div>"
      } else if (row.offerstatus.toLowerCase() == "offers rejected") {
        offerStatusString = "<div class=" + 'offerrejected' + "></div>"
      } else {
        offerStatusString = "<div class=" + 'offerrejected' + ">" + row.offerstatus + "</div>"
      }
      datarow += offerStatusString + "   </div>";
      row.items.forEach(function (crow) {
        datarow += " <div class='col-md-10 mtablechildRow' ><div class='col-md-12 mtablechildRow' >";
        datarow += " <div class='col-md-3 mtableCell text-center align-middle'><label>" + crow.pharmacy + "</label> </div> ";
        datarow += "  <div class='col-md-2 mtableCell text-center'><label> " + crow.brand_name + "</label>  </div>";
        datarow += " <div class='col-md-3 mtableCell text-center'><label>" + crow.baseLine_projection + "</label>  </div>";
        datarow += "  <div class='col-md-3 mtableCell text-center'><label>" + crow.incremental_projection + "</label>  </div>";
        datarow += "  <div class='col-md-1 mtableCell text-center'><label>" + crow.roi_in_percentage + "</label>  </div>";
        datarow += " </div> ";
      });
      datarow += " </div> ";

    });
    pharmacyGridBody.innerHTML = datarow;
  }

  CreateTableFromJSON(data) {
    this.myModelObjList = [];
    var mylist = [];
    //var myModelObj = new PrioritizationGridModel();
    var pharmacyLevelDataMap = data.pharmacyLevelDataMap;
    //console.log(pharmacyLevelDataMap);
    var offerTypes = [
      "offers_not_yet_extended",
      "duration_about_to_mature",
      "offer_extended_not_included",
      "offers_rejected"
    ];
    //debugger
    if (pharmacyLevelDataMap && Object.keys(pharmacyLevelDataMap).length > 0) {
      offerTypes.forEach(function (el) {
        //debugger;  
        var groupByOfferData = pharmacyLevelDataMap[el];
        //console.log("groupByOfferData" , groupByOfferData) ;
        if (groupByOfferData && groupByOfferData.length != 0) {
          groupByOfferData.forEach(function (obj) {
            var myModelObj = new PrioritizationGridModel();
            //var mycurrent_row = document.createElement("tr");
            myModelObj.offerStatus = obj["offerStatus"];
            myModelObj.pharmacyName = obj["pharmacyName"];
            var brandAggregatedDetails = obj["brandAggregatedDetails"];
            var baselineProj = [];
            var incrementalProj = [];
            var roi = [];
            var brandInfoList = [];
            for (var i = 0; i < brandAggregatedDetails.length; i++) {
              var brandInfo = new BrandInfo();
              brandInfo.brandName = brandAggregatedDetails[i].brand_name;
              brandInfo.baselineProjection = brandAggregatedDetails[i].baseLine_projection;
              brandInfo.incrementalProjection = brandAggregatedDetails[i].incremental_projection;
              brandInfo.roi = brandAggregatedDetails[i].roi_in_percentage;
              brandInfoList.push(brandInfo);
            }
            myModelObj.brands = brandInfoList;
            mylist.push(myModelObj);
            console.log("mylist**", mylist);
          });
        }

      });

      this.myModelObjList = mylist;
      //console.log(this.myModelObjList);
    } else {
      //console.log("else**********************************");
      //this.myModelObjList = [];
      this.myModelObjList = [];
    }
    //console.log("myModelObjList",this.myModelObjList);
  }

  createMapMarkerForPrioritization(data): void {
    let mymap1;

    if (this.country == "pakistan") {

      mymap1 = new google.maps.Map(document.getElementById('prioritizationmap'), {
        zoom: 8,
        center: { lat: 30.3753204, lng: 69.3451157 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })

    }
    if (this.country == "india") {

      mymap1 = new google.maps.Map(document.getElementById('prioritizationmap'), {
        zoom: 8,
        center: { lat: 20.5937, lng: 78.9629 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })

    }
    else if (this.country == "turkey") {
      mymap1 = new google.maps.Map(document.getElementById('prioritizationmap'), {
        zoom: 8,
        center: { lat: 20.5937, lng: 78.9629 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }
    else if (this.country == "turkey") {
      mymap1 = new google.maps.Map(document.getElementById('prioritizationmap'), {
        zoom: 8,
        center: { lat: 41.0082, lng: 28.9784 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }
    else if (this.country == "argentina") {
      mymap1 = new google.maps.Map(document.getElementById('prioritizationmap'), {
        zoom: 8,
        center: { lat: 33.9391, lng: 67.7100 },
        zoomControl: true,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
      })
    }

    let icon;
    let marker;
    let locationCount;
    var bounds = new google.maps.LatLngBounds();
    let latLng;
    let lat;
    let lng;
    let pharmsName;
    let offerStatus = "";
    //debugger;
    var pharmacyLevelDataMap = data.pharmacyLevelDataMap;
    var offerTypes = [
      "offers_not_yet_extended",
      "duration_about_to_mature",
      "offer_extended_not_concluded",
      "offers_rejected"];

    //  offerTypes.forEach(function (el) {
    for (var m = 0; m < offerTypes.length; m++) {
      //console.log(pharmacyLevelDataMap[offerTypes[m]]);
      var groupByOfferData = pharmacyLevelDataMap[offerTypes[m]];
      if (groupByOfferData && groupByOfferData.length != 0) {
        for (var i = 0; i < groupByOfferData.length; i++) {
          offerStatus = offerTypes[m];// groupByOfferData[i].offerStatus;
          //console.log("    ********   "+offerStatus);
          pharmsName = groupByOfferData[i].pharmacyName;
          lat = groupByOfferData[i].lat;
          lng = groupByOfferData[i].longitude;
          //console.log("longitute", lat, lng);
          if (offerStatus.toLocaleLowerCase().indexOf("offer_extended_not_concluded") != -1) {
            icon = "../../../../assets/img/mm_20_green.png"
          } else if (offerStatus.toLocaleLowerCase() == "offers_rejected" || offerStatus.toLocaleLowerCase().indexOf("rejected") !== -1) {
            icon = "../../../../assets/img/mm_20_red.png";
          } else if (offerStatus.toLocaleLowerCase() == "offers_not_yet_extended") {
            icon = "../../../../assets/img/mm_20_brown.png";
          } else if (offerStatus.toLocaleLowerCase() == "duration_about_to_mature") {
            icon = "../../../../assets/img/mm_20_blue.png"
          }

          latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
          //pharmsName = data.pharmacies[i].name;
          //console.log(icon);
          marker = new google.maps.Marker({
            map: mymap1,
            zoom: 16,
            center: new google.maps.LatLng(2.8, -187.3),
            icon: icon,
            position: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
            title: pharmsName
          });
          bounds.extend(latLng);
          this.markers.push(marker);
          marker.setMap(mymap1);
        }
      } else {
        this.markers.forEach(function (markerOld) {
          // console.log("markerOld=",markerOld);  
          // markerOld.setMap(null);
        });
      }
    }
    //});

    mymap1.fitBounds(bounds);
  }

  getofferselectionData(filterdata) {
    let url = apiurl + "api/getOffersProjectionTableData";
    return this.http.post(url, filterdata, {}).map(
      data => {
        this.offerselectionData = data;
        return this.offerselectionData;
      },
      err => {
        alert('failed');
      });
  }

  prepareOfferselectionGridRowData(data) {
    var pharmacies = data.pharmacies;
    var pharmacyName;
    var offerStatus;
    var productName;
    var brandName;
    var skuName;
    var volume_baselineProjection;
    var volume_plan1;
    var discount_baselineProjection;
    var discount_plan1;
    var discountpercentage_baselineProjection;
    var discountpercentage_plan1;
    var totlanetsales_baselineProjection;
    var totlanetsales_plan1;
    var totalmargins_baselineProjection;
    var totalmargins_plan1;
    let rowData = [];


    for (var i = 0; i < pharmacies.length; i++) {
      pharmacyName = pharmacies[i].name;
      for (var p = 0; p < pharmacies[i].products.length; p++) {
        brandName = pharmacies[i].products[p].brand;
        //console.log(brandName);
        for (var s = 0; s < pharmacies[i].products[p].skuDetails.length; s++) {
          skuName = pharmacies[i].products[p].skuDetails[s].name;
          for (var o = 0; o < pharmacies[i].products[p].skuDetails[s].offerDetails.length; o++) {
            offerStatus = pharmacies[i].products[p].skuDetails[s].offerDetails[o].status;
            volume_baselineProjection = pharmacies[i].products[p].skuDetails[s].offerDetails[o].baseline_projection;
            volume_plan1 = pharmacies[i].products[p].skuDetails[s].offerDetails[o].offer_volume;
            discount_baselineProjection = pharmacies[i].products[p].skuDetails[s].offerDetails[o].baseline_projection;
            discount_plan1 = pharmacies[i].products[p].skuDetails[s].offerDetails[o].offer_discount_price;
            discountpercentage_baselineProjection = pharmacies[i].products[p].skuDetails[s].offerDetails[o].baseline_projection;
            discountpercentage_plan1 = pharmacies[i].products[p].skuDetails[s].offerDetails[o].offer_discount_percentage;
            totalmargins_baselineProjection = pharmacies[i].products[p].skuDetails[s].offerDetails[o].baseline_projection;
            totalmargins_plan1 = pharmacies[i].products[p].skuDetails[s].offerDetails[o].totalMargins;
            totlanetsales_baselineProjection = pharmacies[i].products[p].skuDetails[s].offerDetails[o].baseline_projection;
            totlanetsales_plan1 = pharmacies[i].products[p].skuDetails[s].offerDetails[o].totalNetSales;
            rowData.push({ decisionTaken: offerStatus, product: [{ pharmacyTotal: 350, brandTotal: 350, skuname: skuName, volume_baselineProjection: volume_baselineProjection, volume_plan1: volume_plan1, discount_baselineProjection: discount_baselineProjection, discount_plan1: discount_plan1, discountpercentage_baselineProjection: discountpercentage_baselineProjection, discountpercentage_plan1: discountpercentage_plan1, totalmargins_baselineProjection: totalmargins_baselineProjection, totalmargins_plan1: totalmargins_plan1, totlanetsales_baselineProjection: totlanetsales_baselineProjection, totlanetsales_plan1: totlanetsales_plan1 }] })
          }
        }
      }
    }
    return rowData;
  }

  updateHomeScreen(jsondata) {
    document.getElementById("offeraccdiv").style.width = 0 + "%";
    document.getElementById("offeracclbl").innerText = 0 + "%";
    document.getElementById("offerrejdiv").style.width = 0 + "%";
    document.getElementById("offerrejlbl").innerText = 0 + "%";
    document.getElementById("offerextenddiv").style.width = 0 + "%";
    document.getElementById("offerextendlbl").innerText = 0 + "%";
    document.getElementById("offernotyetdiv").style.width = 0 + "%";
    document.getElementById("offernotyetlbl").innerText = 0 + "%";

    document.getElementById("offeraccval").innerText = "(" + 0 + ")";
    document.getElementById("offerrejval").innerText = "(" + 0 + ")";
    document.getElementById("offerextendval").innerText = "(" + 0 + ")";
    document.getElementById("offernotyetval").innerText = "(" + 0 + ")";

    (<HTMLInputElement>document.getElementById("securedVal")).innerHTML = "";
    (<HTMLInputElement>document.getElementById("potentialVal")).innerHTML = "";

    document.getElementById("visitedPharmacies").innerText = "";
    document.getElementById("yettovisit").innerText = "";
    let genericMappingData = jsondata.genericMappingData;
    let offeraggrigationData = jsondata.genericMappingData.offerAggregationData;
    let offerAggregatedCount = jsondata.genericMappingData.offerAggregatedCount;
    //this.homeComp.offerAceptedValue = (offeraggrigationData["offer accepted"] || 0)+"%";
    let offerVal: number = (offeraggrigationData["Offer accepted"]) + (offeraggrigationData["inactive"]);

    //alert(Math.round(offerVal * 100) / 100);
    document.getElementById("offeraccdiv").style.width = ((offeraggrigationData["Offer accepted"]) + (offeraggrigationData["inactive"]) || 0) + "%";
    document.getElementById("offeracclbl").innerText = Math.round(offerVal * 100) / 100 + "%";
    document.getElementById("offerrejdiv").style.width = (offeraggrigationData["Offer rejected"] || 0) + "%";
    document.getElementById("offerrejlbl").innerText = (offeraggrigationData["Offer rejected"] || 0) + "%";
    document.getElementById("offerextenddiv").style.width = (offeraggrigationData["Offer extended, response awaited"] || 0) + "%";
    document.getElementById("offerextendlbl").innerText = (offeraggrigationData["Offer extended, response awaited"] || 0) + "%";
    document.getElementById("offernotyetdiv").style.width = (offeraggrigationData["Offer not yet extended"] || 0) + "%";
    document.getElementById("offernotyetlbl").innerText = (offeraggrigationData["Offer not yet extended"] || 0) + "%";

    document.getElementById("offeraccval").innerText = "(" + ((offerAggregatedCount["Offer accepted"]) + (offerAggregatedCount["inactive"]) || 0) + ")";
    document.getElementById("offerrejval").innerText = "(" + (offerAggregatedCount["Offer rejected"] || 0) + ")";
    document.getElementById("offerextendval").innerText = "(" + (offerAggregatedCount["Offer extended, response awaited"] || 0) + ")";
    document.getElementById("offernotyetval").innerText = "(" + (offerAggregatedCount["Offer not yet extended"] || 0) + ")";

    // alert(localStorage.getItem("country"));
    if (localStorage.getItem("country") != "indonesia") {
      (<HTMLInputElement>document.getElementById("securedVal")).innerHTML = genericMappingData.securedValue.toLocaleString('en');
      (<HTMLInputElement>document.getElementById("potentialVal")).innerHTML = genericMappingData.potentialValue.toLocaleString('en');
    }

    document.getElementById("visitedPharmacies").innerText = jsondata.visistedPharmaciesCount;
    document.getElementById("yettovisit").innerText = jsondata.yetToVisitPharmaciesCount;

  }


  offerStatusselect(offerstatus) {
    let offerSatusURL = apiurl + "api/filterPharmaciesByOfferStatus?repCode=" + JSON.parse(localStorage.getItem("repcode")) + "&offerStatus=" + offerstatus + "&tab=" + localStorage.getItem("activetab") + "&frequency=" + localStorage.getItem("selectedFrq");
    return this.http.get(offerSatusURL, {}).map(
      (data: Response) => {
        this.offerstatusData = data;
        return this.offerstatusData;
      },
      err => {
        alert('failed to get selected offer data');
      });
  }

  hideleftsideOpenPanels() {
    let segmentComp = document.getElementById("slider");
    segmentComp.hidden = true;
    let citybrickComp = document.getElementById("citybrickslider");
    citybrickComp.hidden = true;
    let brandComp = document.getElementById("brandslider");
    brandComp.hidden = true;
    let channelComp = document.getElementById("channelslider");
    channelComp.hidden = true;
    let therepyComp = document.getElementById("therepyslider");
    therepyComp.hidden = true;
    let pharmacyCmp = document.getElementById("pharmacyslider");
    pharmacyCmp.hidden = true;
    let frequencyComp = document.getElementById("frquencyslider");
    frequencyComp.hidden = true;

  }

  getOfferstatusForOfferSelection() {
    let baseTableOffersURL = apiurl + "api/getOffersStatusList?country:" + localStorage.getItem("country");
    return this.http.get(baseTableOffersURL, {}).map(
      (data: Response) => {
        this.baseTableOffers = data;
        return this.baseTableOffers;
      },
      err => {
        alert('failed to get basetable offers data');
      });
  }

  getEAgreementStatus() {
    var splitphar = localStorage.getItem("pharmacyname-code").split("-");
    let agreementURL = apiurl + "api/getEAgreementStatus?veeva_code=" + splitphar[0];
    console.log(agreementURL);
    return this.http.get(agreementURL, {}).map(
      (data: boolean) => {
        return data;
      },
      err => {
        alert('failed to get therepy data');
      });
  }

  insertEAgreementStatus() {
    var splitphar = localStorage.getItem("pharmacyname-code").split("-");
    let insertAgreementURL = apiurl + "api/insertEAgreementStatus?veeva_code=" + splitphar[0];
    console.log(insertAgreementURL);
    return this.http.post(insertAgreementURL, {}).map(
      (data: Response) => {
        return data;
      },
      err => {
        alert('failed to get therepy data');
      });
  }
  /*
  getAggregatedOfferValues(requestParams) {
    let url = apiurl + "api/updatePharmacyWithOfferDecisionTaken?" + requestParams;
    return this.http.get(url, {}).map(
      (data: Response) => {
        return data;
      },
      err => {
        alert('failed to get basetable offers data');
      });
  }
*/
  getAggregatedOfferValues(filterdata) {
    console.log('Filterdata is vkp.....' + JSON.stringify(filterdata));
    let url = apiurl + "api/updatePharmacyWithOfferDecisionTaken";
    return this.http.post(url, filterdata, {}).map(
      data => {
        //this.offerselectionData = data;
        //return this.offerselectionData;
        return data;
      },
      err => {
        alert('failed');
      });
  }

  updateOfferUnlockService(offerid: any, country: any, unlockReason: any) {
    console.log("offerid::" + offerid + "  country::" + country + "  unlockReason::" + unlockReason);

    let offerUnlockAPI = apiurl + "api/getOfferUnlockRequest?offer_id=" + offerid + "&country=" + localStorage.getItem("country") + "&reason_code=" + unlockReason;
    return this.http.get(offerUnlockAPI, { headers: { 'Content-Type': 'application/json; charset=utf-8' } }).map(
      data => {
        return data;
        //   return this.unlockResponse;
      },
      err => {
        alert('some thing went wrong...');
      });
  }


  updateOfferstatusforSelection(requestData) {
    let baseTableOffersURL = apiurl + "api/updatePharmacyWithOfferDecision";
    return this.http.post(baseTableOffersURL, requestData, {}).map(
      data => {
        //this.pharmaciesData = data;
        // return this.pharmaciesData;
      },
      err => {
        alert('failed');
      });
  }

  setOfferStatus(value, text, data) {
    this.updateOfferstatusforSelection(data).subscribe(result => {
      //console.log(result);
    })
  }

  getActiveTab(): string {
    var lis = document.getElementById("headernavUl").getElementsByTagName("li");
    for (var i = 0; i < lis.length; i++) {
      if (lis[i].getElementsByTagName("a")[0].className == "activecls") {
        this.activeTab = lis[i].getElementsByTagName("a")[0].innerHTML
      }
    }
    return this.activeTab;
  }

  getselectedPlanAndFrequency() {
    var selectedPlan = "1";
    var selectedFrequency = "HalfYearly";
    var selectedOfferBasicFilters = []
    var plans = document.getElementsByName("plan");
    for (var i = 0; i < plans.length; i++) {
      if ((<HTMLInputElement>plans[i]).checked)
        selectedPlan = (<HTMLInputElement>plans[i]).value;
      //console.log(this.selectedPlan);
    }
    var frequencies = document.getElementsByName("frequency");
    for (var i = 0; i < frequencies.length; i++) {
      if ((<HTMLInputElement>frequencies[i]).checked)
        selectedFrequency = (<HTMLInputElement>frequencies[i]).value;
    }
    return selectedPlan + "," + selectedFrequency;
  }

  getLanguagesi18n() {
    //let offerSatusURL = apiurl + "api/filterPharmaciesByOfferStatus?repName=Jack&offerStatus=" + offerstatus;
    this.getOfferstatusForOfferSelection(); // added for get offer status nxk22478 9/30/2020
    let offerSatusURL = apiurl + "api/getLanguages?country=" + localStorage.getItem("country");
    return this.http.get(offerSatusURL, {}).map(
      (data: Response) => {
        if (data) {
          this.languages = data;
          return this.languages;
        }
      },
      err => {
        alert('failed to get Languages for i18n');
      });
  }

  DownloadData(requestData): Observable<any> {
    let baseTableOffersURL = apiurl + "api/downloadPrioritizedPharmacies.xlsx";
    return this.http.post(baseTableOffersURL, requestData, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  DownloadOffersData(requestData): Observable<any> {
    let baseTableOffersURL = apiurl + "api/downloadOfferDetails.xlsx";
    return this.http.post(baseTableOffersURL, requestData, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    })
  }

  updateGridLegendsOnChange() {
    this.lngChange.emit();
  }

  updateCurrentSeslectionOnChange() {
    this.lngChangeCurrentSelection.emit();
  }

  loadBasicTableDataOnChange() {
    this.lngloadBasicTableData.emit();
  }

  summaryGridTableDataOnChange() {
    this.lngSummaryGridTableData.emit();
  }

  capitalize(s) {
    var capStr = "";
    if (s) {
      s.split(' ').forEach(str => {
        capStr += ' ' + str[0].toUpperCase() + str.slice(1);
      });
    }
    return capStr;
  }

}